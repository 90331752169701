import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Space, Typography} from "antd";
import {useDispatch} from "react-redux";
import { calculateFees, sellCrypto } from "../../../../store/actions/trades";
import CurrencySelector from "../../../../components/CurrencySelector/CurrencySelector";
import usdtCoin from "../../../../assets/svg/usdt_coin.svg";
import euroCoin from "../../../../assets/svg/euro_coin.svg";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../../schemas";
import {formatNumberPrecision} from "../../../../utils/helpers";
import useTransaction from "../../../../hooks/useTransaction";
import {CURRENCY, TRANSACTION_TYPE} from "../../../../utils/constants";

const exchangeToArr =  [{value: "EUR", label: "EUR", src: euroCoin}];
const exchangeFromArr =  [{value: "USDT", label: "USDT", src: usdtCoin}];

const SellForm = ({ isAvailable, user, getTradeInfo, onSubmit }) => {
	const [tradeData, setTradeData] = useState({
		fee: null,
		course: null,
		min_sum: null,
		max_sum: null,
		fee_eur: null,
		fee_crypto: null
	})
	const [form] = Form.useForm();
	const {onFormSubmitSuccess, loading} = useTransaction(user, onSubmit, form);
	const receiveCurrency = Form.useWatch('receiveCurrency', form);
	const payCurrency = Form.useWatch('payCurrency', form);

	useEffect(() => {
		if (receiveCurrency && payCurrency) {
			getTradeInfo("SELL", {toState: false}).then((resp) => {
				setTradeData(resp);
				calculateFees(TRANSACTION_TYPE.SELL, payCurrency, form.getFieldValue('payAmount'), false)
					.then(({ fee_eur, fee_crypto }) => {
						setTradeData(prevState => ({
							...prevState,
							fee_eur,
							fee_crypto
						}))
					})
			});
		}
	}, [receiveCurrency, payCurrency]);

	const onFinish = useCallback((formData) => {
		onFormSubmitSuccess(TRANSACTION_TYPE.SELL, CURRENCY.USDT, formData.payAmount);
	}, [onFormSubmitSuccess]);

	const onFormFieldsChange = useCallback((field, allFields) => {
		const changedField = field[0];
		if (changedField.name[0] === 'payCurrency' || changedField.name[0] === 'receiveCurrency') {
			form.resetFields(['payAmount', 'receiveAmount']);
		}
		else if (changedField.name[0] === 'payAmount' && changedField.value >= 0) {
			const calculatedReceiveAmount = formatNumberPrecision(changedField.value / tradeData.course, 3);
			form.setFieldValue('receiveAmount', Math.min(calculatedReceiveAmount, tradeData.max_sum));
		}
		else if (changedField.name[0] === 'receiveAmount' && changedField.value >= 0) {
			const calculatedPayAmount = formatNumberPrecision(changedField.value * tradeData.course, 3);
			form.setFieldValue('payAmount', Math.max(calculatedPayAmount, tradeData.min_sum));
		}
	}, [tradeData]);

	return (
		<Form validateMessages={validateMessages} validateTrigger="onChange" onFieldsChange={onFormFieldsChange} initialValues={{
			payCurrency: exchangeFromArr[0].value,
			receiveCurrency: exchangeToArr[0].value,
		}} form={form} disabled={(tradeData.min_sum === 0 && tradeData.max_sum === 0) || !isAvailable} size="large" layout="horizontal" labelAlign="left" onFinish={onFinish} labelWrap>
			<Form.Item rules={GENERAL_SCHEME.required} label="Pay" name="payAmount">
				<InputNumber className="w100" max={tradeData.max_sum} min={tradeData.min_sum}
				             precision={2} placeholder={`${tradeData.min_sum} - ${tradeData.max_sum}`}
				             addonAfter={<CurrencySelector arr={exchangeFromArr} name="payCurrency"/>}/>
			</Form.Item>
			<Form.Item rules={GENERAL_SCHEME.required} label="Receive" name="receiveAmount">
				<InputNumber className="w100" max={formatNumberPrecision(tradeData.max_sum / tradeData.course, 2)} min={formatNumberPrecision(tradeData.min_sum / tradeData.course, 2)}
				             precision={2} placeholder={`${formatNumberPrecision(tradeData.min_sum / tradeData.course, 2)} - ${formatNumberPrecision(tradeData.max_sum / tradeData.course, 2)}`}
				             addonAfter={<CurrencySelector arr={exchangeToArr} name="receiveCurrency"/>}/>
			</Form.Item>
			<Space className="w100" direction="vertical" align="center">
				<Button type="primary" onClick={form.submit}>Sell {form.getFieldValue('payCurrency')}</Button>
				<Typography.Text>Exchange fee: {tradeData.fee}% + {tradeData.fee_crypto} USDT, (min. 0.99 EUR)</Typography.Text>
			</Space>
		</Form>
	);
}

export default SellForm;
