export const FAQData = [
	{
		title: 'What is CryptoLite?',
		children: [
			// {
			// 	title: 'About CryptoLite',
			// 	desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            //     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            //     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            //     aliquip ex ea commodo consequat. Duis aute irure dolor in
            //     reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            //     pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            //     culpa qui officia deserunt mollit anim id est laborum.`
			// },
			{
				title: "CryptoLite on social networks",
				desc: `<a href="https://www.instagram.com/kryptolite_official/" target="_blank">Kryptolite in instagram</a>`
			},
			{
				title: "Investing in CryptoLite",
				desc: "Quppy creates and develops cutting-edge technologies, ideas and business concepts. If you want to co-create the ecosystem of innovative finances, we invite you to contact us (both individual and institutional investors) via e-mail info@quppy.com. Our business development team offers various investment opportunities for everyone who wants to contribute their knowledge, experience, business contacts and financial resources for new financial investments, in order to boost the the Quppy solution growth."
			},
			{
				title: "What is a cryptocurrency exchange?",
				desc: "A cryptocurrency exchange is an online service that allows you to exchange cryptocurrencies for fiat money (dollars, euros, etc.) or other cryptocurrencies."
			},
			{
				title: "How can I start using your service?",
				desc: `To start using our cryptocurrency exchange, you need to:
				1. Register on our website or Weststein mobile app.
				2. Undergo identity verification.
				3. Top up your balance with cryptocurrency or fiat money.
				4. Select the exchange direction and complete the transaction.`
			},
			{
				title: "How do you ensure the safety of my funds?",
				desc: "We use advanced encryption and cryptocurrency storage methods to ensure maximum security of your funds. All transactions are processed through reliable blockchain networks."
			},
			{
				title: "What personal data do you collect?",
				desc: "We only collect the information necessary for verification and transaction processing, such as your name, email address, and payment details. This data is strictly confidential and is not shared with third parties."
			},
		]
	},
	// {
	// 	title: "Fees and Limits",
	// 	children: [
	// 		// {
	// 		// 	title: "What is the minimum EUR, GBP or USD transaction amount in CryptoLite Wallet?",
	// 		// 	desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //         //     eiusmod tempor incididunt ut labore et dolore magna aliqua.`
	// 		// },
	// 		{
	// 			title: "How to choose the Network fee?",
	// 			desc: `Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		// {
	// 		// 	title: "What fees are charged for the CryptoLite Wallet services in EUR, GBP and USD?",
	// 		// 	desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //         //     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //         //     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //         //     aliquip ex ea commodo consequat. Duis aute irure dolor in
    //         //     reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //         //     pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //         //     culpa qui officia deserunt mollit anim id est laborum.`
	// 		// },
	// 		// {
	// 		// 	title: "Why do I need to pay a Network Fee?",
	// 		// 	desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //         //     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //         //     minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //         //     aliquip ex ea commodo consequat.`
	// 		// }

	// 	]
	// },
	// {
	// 	title: "Accounts",
	// 	children: [
	// 		{
	// 			title: "How to identify my GBP IBAN sort code?",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "Downloading the Quppy app",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "How to verify my account?",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "How can I create my CryptoLite IBAN account?",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		}

	// 	]
	// },
	// {
	// 	title: "Legal",
	// 	children: [
	// 		{
	// 			title: "May 2022 cash back Promo Rules",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "Christmas Promo rules: 20.12.2021-01.01.2022",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "CryptoLite Referral program",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "Licenses",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		},
	// 		{
	// 			title: "Promo rules 25.11 - 02.12. 2021",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`

	// 		},
	// 		{
	// 			title: "Cyber Monday Promo rules - 30.11.2021-05.12.2021",
	// 			desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    //             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
    //             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
    //             aliquip ex ea commodo consequat. Duis aute irure dolor in
    //             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    //             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    //             culpa qui officia deserunt mollit anim id est laborum.`
	// 		}

	// 	]
	// }
]
