import {PURGE} from "redux-persist/es/constants";
import {AML_STATUS} from "../../utils/constants";

const initialState = {
	authKey: null,
	address: '',
	confirm: {
		email_confirm: false,
		phone_confirm: false,
		sumsub_status: null,
		aml_status: AML_STATUS.NOT_CHECKED,
		fiat_wallet: '',
	},
	balance_usdt: null,
	balance_tron: null,
	balance_fiat: null,
	fiat_topup: '',
	fiat_topup_bic: '',
	fiat_topup_iban: '',
	fiat_topup_name: '',
	transactions: [],
	email: null,
	meta: {},
	avatarUrl: ''
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'User/SET': {
			if (action.payload.meta) action.payload.meta = { ...state.meta, ...action.payload.meta };
			if (action.payload.confirm) action.payload.confirm = { ...state.confirm, ...action.payload.confirm };

			return {...state, ...action.payload};
		}

		case 'User/SET_AVATAR': {
			return {...state, avatarUrl: action.payload}
		}

		case PURGE: {
			return initialState;
		}

		default:
			return state;
	}
};

export default userReducer;
