import React, {forwardRef, useCallback, useState} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Space} from "antd";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../../schemas";
import moment from "moment/moment";
import classes from "../Survey.module.scss";
import dayjs from "dayjs";
import {IS_DEV} from "../../../utils/constants";

const defaultInitialValues = {
    firstName: 'JOHN',
    lastName: 'DOE',
    middleName: "",
    dateOfBirth: dayjs('1990-01-01'),
    countryOfBirth: 'RU',
    citizenship: 'RU',
    nationality: 'RU',
    idCountry: 'RU',
    idAuthority: 'MVD',
    idNumber: '402023',
    idIssueDate: dayjs('2010-01-01'),
    idExpiryDate: dayjs('2024-01-01'),
}
const PersonalInformationSection = forwardRef(({countries, initialValues, footer, formDataInit = {}, resetStep, ...props}, ref) => {
    const [formData, setFormData] = useState(formDataInit)
    const [form] = Form.useForm();

    const resetFields = () => form.resetFields();
    const resetForm = () => {
        resetFields();
        setFormData({})
        resetStep()
    }

    const filterOption = (input, option) => {
        return option.label.props.children[1].props.children.toLowerCase().startsWith(input.toLowerCase())
    };

    return (
        <Form ref={ref} initialValues={formData ? formData : initialValues ? initialValues : IS_DEV && defaultInitialValues} validateMessages={validateMessages} layout="vertical" size="large" form={form} validateTrigger="onChange" name="step0" {...props}>
            {/*NAME SECTION*/}
            <Row gutter={20}>
                <Col xs={24} sm={12}>
                    <Form.Item normalize={value => value.trim().toUpperCase()} rules={[...FIELDS_SCHEME.name, ...GENERAL_SCHEME.required]} label="First name" name="firstName">
                        <Input placeholder="First name" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item normalize={value => value.trim().toUpperCase()} rules={[...FIELDS_SCHEME.surname, ...GENERAL_SCHEME.required]} label="Last name" name="lastName">
                        <Input placeholder="Last name" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item normalize={value => value.trim().toUpperCase()} rules={FIELDS_SCHEME.patronymic} label="Middle name (if applicable)" name="middleName">
                        <Input placeholder="Middle name" />
                    </Form.Item>
                </Col>
            </Row>

            {/*BIRTH SECTION*/}
            <Row gutter={20}>
                <Col xs={24} sm={12}>
                    <Form.Item label="Date of birth" rules={GENERAL_SCHEME.required} name="dateOfBirth">
                        <DatePicker disabledDate={current => current && current > moment().subtract(18, 'years').endOf('day')} showToday={false} className="w100" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item label="Place of birth (country)" rules={GENERAL_SCHEME.required} name="countryOfBirth">
                        <Select placeholder="Place of birth (country)" options={countries.map(country => ({
                            value: country.cca2,
                            label: <div  style={{ display: 'flex' }}>
                                <div
                                  className={`fi fi-${country.cca2.toLowerCase()}`}
                                  style={{ marginRight: 6 }}
                                />
                                <div>{country.name}</div>
                            </div>
                        }))} 
                        filterOption={filterOption} 
                        allowClear 
                        showSearch />
                    </Form.Item>
                </Col>
            </Row>

            {/*NATIONALITY SECTION*/}
            <Row gutter={20}>
                <Col xs={24} sm={12}>
                    <Form.Item label="Citizenship" rules={GENERAL_SCHEME.required} name="citizenship">
                        <Select placeholder="Citizenship" options={countries.map(country => ({
                            value: country.cca2,
                            label: <div  style={{ display: 'flex' }}>
                                <div
                                  className={`fi fi-${country.cca2.toLowerCase()}`}
                                  style={{ marginRight: 6 }}
                                />
                                <div>{country.name}</div>
                            </div>
                        }))}  
                        filterOption={filterOption} 
                        allowClear showSearch />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item label="Nationality" rules={GENERAL_SCHEME.required} name="nationality">
                        <Select placeholder="Nationality" options={countries.map(country => ({
                            value: country.cca2,
                            label: <div  style={{ display: 'flex' }}>
                                <div
                                  className={`fi fi-${country.cca2.toLowerCase()}`}
                                  style={{ marginRight: 6 }}
                                />
                                <div>{country.name}</div>
                            </div>
                        }))}  
                        filterOption={filterOption} 
                        allowClear showSearch />
                    </Form.Item>
                </Col>
            </Row>

            {/*PASSPORT SECTION*/}
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Passport / ID No" rules={[...GENERAL_SCHEME.alphaNumeric, ...GENERAL_SCHEME.required]} name="idNumber">
                        <Input placeholder="Passport / ID No" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Passport / ID issuing country" rules={GENERAL_SCHEME.required} name="idCountry">
                        <Select placeholder="Passport / ID issuing country" options={countries.map(country => ({
                            value: country.cca2,
                            label: <div  style={{ display: 'flex' }}>
                                <div
                                  className={`fi fi-${country.cca2.toLowerCase()}`}
                                  style={{ marginRight: 6 }}
                                />
                                <div>{country.name}</div>
                            </div>
                        }))} 
                        filterOption={filterOption} 
                        allowClear showSearch />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Passport / ID issuing authority" rules={GENERAL_SCHEME.required} name="idAuthority">
                        <Input placeholder="Passport / ID issuing authority" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Passport / ID issue date" rules={GENERAL_SCHEME.required} name="idIssueDate">
                        <DatePicker disabledDate={current => current && (current > moment().endOf('day'))} showToday={false} className="w100" hideDisabledOptions />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Passport / ID expiry date" rules={GENERAL_SCHEME.required} name="idExpiryDate">
                        <DatePicker disabledDate={current => current && current <= moment().endOf('day')} showToday={false} className="w100" hideDisabledOptions />
                    </Form.Item>
                </Col>
            </Row>
            {footer ? footer({ form }) : (
                <Row justify="end">
                    <Space wrap="wrap">
                        <Button type="text" onClick={resetForm}>
                            Reset
                        </Button>
                        {/*<Button onClick={}>*/}
                        {/*    Back*/}
                        {/*</Button>*/}
                        
                        <Button onClick={form.submit}>
                            Next
                        </Button>
                    </Space>
                </Row>
            )}
        </Form>
    );
});

export default PersonalInformationSection;
