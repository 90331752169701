import React, {useCallback} from 'react';
import {Button, Col, Collapse, Form, Grid, Input, message, Row, Select, Space, Typography} from "antd";
import classes from "../Support/Support.module.scss";
import Container from "../../components/Container/Container";
import {FAQData} from "./FAQData";
import {sendContactForm} from "../../store/actions/user";
import {FIELDS_SCHEME, GENERAL_SCHEME, validateMessages} from "../../schemas";
import {useDispatch} from "react-redux";

const issues = [
	{ key: 1, value: "Account Issue", label: "Account Issue" },
	{ key: 2, value: "Fees and Limits", label: "Fees and Limits" },
	{ key: 3, value: "Legal", label: "Legal" },
];

const Support = () => {
	const [form] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();
	const dispatch = useDispatch();

	const onFormSubmitSuccess = useCallback((formData) => {
		dispatch(sendContactForm(formData, "support")).then(() => form.resetFields());
	}, []);

	return (
		<Container>
			<Row gutter={[48,48]}>
				<Col xs={24} lg={12}>
					<div>
						<Typography.Title level={breakpoints.md ? 1 : 3}>Support</Typography.Title>

						{FAQData.map(section => (
							<React.Fragment>
								<Typography.Title level={breakpoints.md ? 4 : 5}>{section.title}</Typography.Title>
								<Collapse ghost>
									{section.children.map(child => (
										<Collapse.Panel header={child.title} key={child.title}>
											<Typography.Paragraph ><div dangerouslySetInnerHTML={{__html: child.desc}}/></Typography.Paragraph>
										</Collapse.Panel>
									))}
								</Collapse>
							</React.Fragment>
						))}
					</div>
				</Col>
				<Col xs={24} lg={12}>
					<div className={classes.formContainerWrapper}>
						<div className={classes.formContainer}>
							<Typography.Title level={breakpoints.md ? 3 : 4}>Submit a request</Typography.Title>
							<Form validateMessages={validateMessages} layout="vertical" form={form} size="large" onFinish={onFormSubmitSuccess} labelWrap>
								<Form.Item label="Issue" name="issue" rules={[{ required: true }]}>
									<Select placeholder="Select issue" options={issues}/>
								</Form.Item>
								{/*<Form.Item label="Email" name="email" rules={[...FIELDS_SCHEME.email, ...GENERAL_SCHEME.required]}>*/}
								{/*	<Input placeholder="Your email"/>*/}
								{/*</Form.Item>*/}
								<Form.Item label="Text" name="comment" rules={[{ required: true }]}>
									<Input.TextArea maxLength={500} placeholder="Type your question..." autoSize={{
										minRows: 3, maxRows: 6
									}}/>
								</Form.Item>

								<div className="text-center">
									<Button onClick={form.submit} type="primary" size="large">SUBMIT</Button>
								</div>
							</Form>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Support;
